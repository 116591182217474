import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import Axios
import Loading from './Loading';
import { FormMessage } from './FormMessage';

const Form = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact_number: '',
    work_Title: '',
    desc: '',
  });

  const [validationMessages, setValidationMessages] = useState({
    name: '',
    email: '',
    contact_number: '',
    work_Title: '',
  });

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear validation message when the user starts typing in the field
    setValidationMessages({
      ...validationMessages,
      [name]: '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if mandatory fields are empty
    const mandatoryFields = ['name', 'email', 'contact_number', 'work_Title'];
    let isValid = true;
    const updatedValidationMessages = {};

    mandatoryFields.forEach((field) => {
      if (!formData[field]) {
        updatedValidationMessages[field] = 'This field is mandatory';
        isValid = false;
      }
    });

    // If any mandatory field is empty, update state to display validation messages
    if (!isValid || (formData.contact_number && formData.contact_number.length !== 10)) {
      setValidationMessages({
        ...updatedValidationMessages,
        contact_number: 'Enter a 10 digit number',
      });
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post("https://mbb-consultancy.onrender.com/contacts/submit" || "http://localhost:8800/contacts/submit", formData, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true, // include credentials (cookies) in the request
      });

      if (response.status === 201) {
        console.log('Form submitted successfully');
        setIsLoading(false);
        setIsFormSubmitted(true);
      } else {
        setIsLoading(false);
        setIsError(true);
        console.error('Form submission failed');
      }
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.error('Error submitting form:', error);
    }
  };

  const handleOkClick = () => {
    // Clear the form and reset state when the "OK" button is clicked
    navigate("/");
  };

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="flex flex-col min-h-screen justify-between bg-slate-200 items-center">
      {(isFormSubmitted || isError) ? (
       <FormMessage isError={isError} handleOkClick={handleOkClick} />
      ) : (
        // Display the form when it's not submitted
        <div className="w-full max-w-screen-md p-8">
          <h2 className="text-4xl font-bold mb-4 text-center">Contact Form</h2>
          <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-medium text-gray-600 mb-2">
              Name<span className="text-red-800 text-lg">*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Enter your name"
              className={`form-input mt-1 block w-full border p-2 rounded focus:outline-none focus:border-blue-500 ${
                validationMessages.name && 'border-red-500'
              }`}
            />
            {validationMessages.name && (
              <p className="text-red-500 text-sm mt-1">{validationMessages.name}</p>
            )}
          </div>
  
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-600 mb-2">
              Email<span className="text-red-800 text-lg">*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter your email"
              className={`form-input mt-1 block w-full border p-2 rounded focus:outline-none focus:border-blue-500 ${
                validationMessages.email && 'border-red-500'
              }`}
            />
            {validationMessages.email && (
              <p className="text-red-500 text-sm mt-1">{validationMessages.email}</p>
            )}
          </div>
  
          <div className="mb-4">
            <label htmlFor="contact_number" className="block text-sm font-medium text-gray-600 mb-2">
              Contact Number<span className="text-red-800 text-lg">*</span>
            </label>
            <input
              type="text"
              id="contact_number"
              name="contact_number"
              value={formData.contact_number}
              onChange={handleInputChange}
              placeholder="Enter contact number"
              className={`form-input mt-1 block w-full border p-2 rounded focus:outline-none focus:border-blue-500 ${
                validationMessages.contact_number && 'border-red-500'
              }`}
            />
            {validationMessages.contact_number && (
              <p className="text-red-500 text-sm mt-1">{validationMessages.contact_number}</p>
            )}
          </div>
  
          <div className="mb-4">
            <label htmlFor="work_Title" className="block text-sm font-medium text-gray-600 mb-2">
              Work Subject<span className="text-red-800 text-lg">*</span>
            </label>
            <input
              type="text"
              id="work_Title"
              name="work_Title"
              value={formData.work_Title}
              onChange={handleInputChange}
              placeholder="Enter work title"
              className={`form-input mt-1 block w-full border p-2 rounded focus:outline-none focus:border-blue-500 ${
                validationMessages.work_Title && 'border-red-500'
              }`}
            />
            {validationMessages.work_Title && (
              <p className="text-red-500 text-sm mt-1">{validationMessages.work_Title}</p>
            )}
          </div>
  
          <div className="mb-4">
            <label htmlFor="desc" className="block text-sm font-medium text-gray-600 mb-2">
              Work Description
            </label>
            <textarea
              id="desc"
              name="desc"
              value={formData.desc}
              onChange={handleInputChange}
              placeholder="Enter work description"
              className="form-textarea mt-1 block w-full border p-2 rounded focus:outline-none focus:border-blue-500"
            ></textarea>
          </div>
  
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 focus:outline-none focus:shadow-outline-green"
            >
              Submit
            </button>
          </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Form;