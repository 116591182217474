import classNames from 'classnames';

export const FormMessage = ({ isError = false, handleOkClick }) => {
  const containerClasses = classNames(
    'font-medium h-44 w-96 mt-8 ml-4 pt-12 text-center rounded-lg border-2',
    {
      'bg-green-200 text-green-900 border-green-500': !isError,
      'bg-red-200 text-red-900 border-red-500': isError,
    }
  );

  const buttonClasses = classNames(
    'text-white px-4 py-2 rounded focus:outline-none focus:shadow-outline-green mt-2 border-2',
    {
      'bg-green-500 hover:bg-green-600 border-green-700': !isError,
      'bg-red-500 hover:bg-red-600 border-red-700': isError,
    }
  );

  const message = isError ? 'Error submitting form!' : 'Form submitted successfully!';

  return (
    <div className={containerClasses}>
      <p className="text-2xl">{message}</p>
      <button onClick={handleOkClick} className={buttonClasses}>
        Go to Home Page
      </button>
    </div>
  );
};
