import React from 'react';
import { CgSpinner } from "react-icons/cg";

const Loading = () => {
  return (
    <>
    <div className='flex justify-center items-center h-lvh bg-slate-200'>
      <div className='flex-col'>
        <div className='flex justify-center'>
          <CgSpinner className="animate-spin text-8xl text-blue-500"/>
        </div>
        <div className='mt-4 text-center text-3xl flex-col pl-4'>
          <p>Please Do not refresh or Go Back.</p>
        </div>
      </div>
      
    </div>
   
    </>
  );
};

export default Loading;
