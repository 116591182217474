import React from 'react';

export default function Location() {
  return (
    <div className="gmap_canvas flex justify-center mb-8 sm:w-full md:w-full lg:w-full">
      <iframe
        title="Google Map"
        src="https://maps.google.com/maps?q=MBB%20Consultancy%20Services%20Pvt%20Ltd%2C%20Kolkata%2C%20India&t=&z=15&ie=UTF8&iwloc=&output=embed"
        frameBorder="0"
        scrolling="no"
        className="rounded-xl shadow-md shadow-black"
        style={{ width: '100%', maxWidth: '100%', height: '470px' }}
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}
